import type { SVGProps } from 'react';
import * as React from 'react';
const SvgCaretBigUpdown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path fill="#5D5E69" d="M18 14.5 12 22l-6-7.5zM18 9.5 12 2 6 9.5z" />
  </svg>
);
export default SvgCaretBigUpdown;
