import type { SVGProps } from 'react';
import * as React from 'react';
const SvgAuthority1Color = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#FFDB1F"
      d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12"
    />
    <path
      fill="url(#Authority-1-color_svg__a)"
      d="M21.913 11.999c0 5.475-4.438 9.913-9.913 9.913s-9.913-4.438-9.913-9.913S6.525 2.086 12 2.086s9.913 4.438 9.913 9.913"
    />
    <path
      fill="url(#Authority-1-color_svg__b)"
      d="M20.348 12.001a8.348 8.348 0 1 1-16.696 0 8.348 8.348 0 0 1 16.696 0"
    />
    <path
      fill="#AD6D0C"
      d="M10.587 16.695h1.994l2.028-9.391H12.62q-.145.09-.436.26-.284.163-.634.371l-.7.417q-.344.201-.634.37-.285.164-.43.254l-.396 1.914q.14-.085.41-.241.27-.164.6-.351.33-.196.661-.385l.601-.357q.27-.164.41-.241h.119z"
    />
    <defs>
      <linearGradient
        id="Authority-1-color_svg__a"
        x1={6.632}
        x2={15.79}
        y1={0}
        y2={24}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFC700" />
        <stop offset={1} stopColor="#FFC658" />
      </linearGradient>
      <linearGradient
        id="Authority-1-color_svg__b"
        x1={6.632}
        x2={15.79}
        y1={0}
        y2={24}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFDE8A" />
        <stop offset={1} stopColor="#F3A100" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgAuthority1Color;
