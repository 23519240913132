import type { SVGProps } from 'react';
import * as React from 'react';
const SvgXSmallCircleColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#E9EAF2"
      fillRule="evenodd"
      d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2M9.207 7.793a1 1 0 0 0-1.414 1.414L10.586 12l-2.793 2.793a1 1 0 1 0 1.414 1.414L12 13.414l2.793 2.793a1 1 0 0 0 1.414-1.414L13.414 12l2.793-2.793a1 1 0 0 0-1.414-1.414L12 10.586z"
      clipRule="evenodd"
    />
    <path
      fill="#7A7B89"
      d="M7.793 7.793a1 1 0 0 1 1.414 0L12 10.586l2.793-2.793a1 1 0 1 1 1.414 1.414L13.414 12l2.793 2.793a1 1 0 0 1-1.414 1.414L12 13.414l-2.793 2.793a1 1 0 0 1-1.414-1.414L10.586 12 7.793 9.207a1 1 0 0 1 0-1.414"
    />
  </svg>
);
export default SvgXSmallCircleColor;
