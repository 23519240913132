import type { SVGProps } from 'react';
import * as React from 'react';
const SvgMessageDotsFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    style={{
      width: `${props.width}px`,
      height: `${props.height}px`,
      minWidth: `${props.width}px`,
      minHeight: `${props.height}px`,
    }}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4C2.89543 4 2 4.89543 2 6V17C2 18.1046 2.89543 19 4 19H8.58579L11.2929 21.7071C11.6834 22.0976 12.3166 22.0976 12.7071 21.7071L15.4142 19H20C21.1046 19 22 18.1046 22 17V6C22 4.89543 21.1046 4 20 4H4ZM8 13C8.82843 13 9.5 12.3284 9.5 11.5C9.5 10.6716 8.82843 10 8 10C7.17157 10 6.5 10.6716 6.5 11.5C6.5 12.3284 7.17157 13 8 13ZM13.5 11.5C13.5 12.3284 12.8284 13 12 13C11.1716 13 10.5 12.3284 10.5 11.5C10.5 10.6716 11.1716 10 12 10C12.8284 10 13.5 10.6716 13.5 11.5ZM17.5 11.5C17.5 12.3284 16.8284 13 16 13C15.1716 13 14.5 12.3284 14.5 11.5C14.5 10.6716 15.1716 10 16 10C16.8284 10 17.5 10.6716 17.5 11.5Z"
      fill={props.fill ? props.fill : '#B8B9C8'}
    />
  </svg>
);
export default SvgMessageDotsFill;
