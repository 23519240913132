import type { SVGProps } from 'react';
import * as React from 'react';
const SvgCrown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      fillRule="evenodd"
      d="M12.03 4a1 1 0 0 1 .884.595l2.478 5.594 4.487-3.554a1 1 0 0 1 1.613.913l-1.5 11.58A1 1 0 0 1 19 20H4.5a1 1 0 0 1-.992-.872l-1.5-11.58a1 1 0 0 1 1.613-.913l4.548 3.601 2.943-5.695a1 1 0 0 1 .918-.54m-.1 3.313-2.542 4.92a1 1 0 0 1-1.509.325L4.308 9.73 5.378 18h12.743l1.071-8.27-3.571 2.828a1 1 0 0 1-1.535-.379z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCrown;
