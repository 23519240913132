import type { SVGProps } from 'react';
import * as React from 'react';
const SvgImport = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      d="M5 5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-5a1 1 0 1 0-2 0v5H5V7h5a1 1 0 1 0 0-2z"
    />
    <path
      fill="#5D5E69"
      d="M15 15a1 1 0 0 0-1-1h-2.586L18.5 6.914A1 1 0 0 0 17.086 5.5L10 12.586V10a1 1 0 0 0-2 0v5a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1"
    />
  </svg>
);
export default SvgImport;
