import type { SVGProps } from 'react';
import * as React from 'react';
const SvgMinusSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}>
    <rect width={10} height={2} x={7} y={11} fill="#5D5E69" rx={1} />
  </svg>
);
export default SvgMinusSmall;
