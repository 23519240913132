import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIsMounted, usePreservedCallback } from '@toss/react';
import { setHttpClientToken } from '@zep/api/httpClient.ts';
import { zepAnalytics } from '@zep/utils';
import { useRouter } from 'next/router';

import { User } from '../../types';

import {
  clearStorageUser,
  getStorageUser,
  setStorageUser,
} from './userStorage.ts';

const defaultUserContext: UserContextType = {
  user: null,
  setUser: (_: User | null) => {},
  logOut: () => {},
  isInitialization: false,
  isAuthenticated: false,
};

export const UserContext = createContext<UserContextType>(defaultUserContext);

export const UserProvider = ({ children }: PropsWithChildren) => {
  const isMounted = useIsMounted();
  const router = useRouter();
  const [isInitialization, setInitialization] = useState(false);

  const [currentUser, setCurrentUser] = useState<User | null>(null);

  const handleUser = (user: User | null) => {
    if (user) {
      setHttpClientToken(user.token);
      setStorageUser(user);
      redirectSignupPage(user);
      setCurrentUser({ ...currentUser, ...user });
      zepAnalytics.setAmplitudeUserId(user.username);
      return;
    }
    setCurrentUser(user);
  };

  const logOut = useCallback(
    async (shouldRedirect = true) => {
      clearStorageUser();
      if (shouldRedirect) {
        await router.push('/landing');
      }
      // withAuth hoc 판단 타이밍 이슈로 인해 원하는 페이지를 먼저 push 후 clear
      setCurrentUser(null);
      setHttpClientToken('');
      zepAnalytics.resetAmplitudeUserId();

      return Promise.resolve();
    },
    [router],
  );

  const isAuthenticated = useMemo<boolean>(() => {
    if (currentUser) return true;
    if (!isInitialization) return false;
    return !!getStorageUser();
  }, [isInitialization, currentUser]);

  const redirectSignupPage = usePreservedCallback((user: User | null) => {
    if (!user) return;
    if (
      router.pathname.startsWith('/login') ||
      router.pathname.startsWith('/sign-up') ||
      router.pathname.startsWith('/trouble')
    ) {
      return;
    }

    if (!user.registrationStatus) {
      router.push('/sign-up/type');
      return;
    }

    if (!user.type || user.type.toLowerCase() === 'unknown') {
      router.push('/sign-up/type');
      return;
    }

    if (user.expiredAt && new Date(user.expiredAt) < new Date()) {
      logOut();
      router.push('/login');
      return;
    }
  });

  useEffect(() => {
    if (!isMounted) return;
    const user = getStorageUser();
    if (user) {
      setCurrentUser(user);
    }
    setInitialization(true);

    redirectSignupPage(user);
  }, [isMounted, redirectSignupPage, router]);

  return (
    <UserContext.Provider
      value={{
        isAuthenticated,
        isInitialization,
        user: currentUser,
        setUser: handleUser,
        logOut,
      }}>
      {children}
    </UserContext.Provider>
  );
};

type UserContextType = {
  user: User | null;
  setUser: (user: User | null) => void;
  logOut: (shouldRedirect?: boolean) => void;
  isInitialization: boolean;
  isAuthenticated: boolean;
};
