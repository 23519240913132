import { forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import { cva, VariantProps } from 'class-variance-authority';

import { cn } from '../../utils';

const inputVariants = cva(
  [
    'space-x-2',
    'inline-flex',
    'bg-white',
    'items-center',
    'align-middle',
    'font-medium',
    'border',
    'border-gray-300',
    'placeholder:text-gray-500',
    'focus-within:border-primary',
  ],
  {
    variants: {
      size: {
        sm: ['px-[10px]', 'h-[36px]', 'rounded-lg', 'text-body-sm'],
        md: ['px-3', 'h-10', 'rounded-lg', 'text-body-lg'],
      },
    },
    defaultVariants: {
      size: 'md',
    },
  },
);

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  { leftSlot, rightSlot, className, size, inputClassName, ...props },
  ref,
) {
  return (
    <div className={cn(inputVariants({ size }), className)}>
      {leftSlot && (
        <span className="whitespace-nowrap text-body-md !leading-none text-inherit">
          {leftSlot}
        </span>
      )}
      <input
        type="text"
        ref={ref}
        className={cn(
          'w-full bg-transparent text-inherit outline-none',
          inputClassName,
        )}
        autoComplete={'off'}
        {...props}
      />
      {rightSlot && (
        <span className="whitespace-nowrap text-body-sm !leading-none text-inherit">
          {rightSlot}
        </span>
      )}
    </div>
  );
});

type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> &
  VariantProps<typeof inputVariants> & {
    leftSlot?: ReactNode;
    rightSlot?: ReactNode;
    inputClassName?: string;
  };
