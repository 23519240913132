import { useState } from 'react';
import { MyQuizButton } from '@zep/layout/Header/PageNavigatorButton.tsx';
import {
  EditNicknameButton,
  LogOutButton,
} from '@zep/layout/Header/UserManagementButton.tsx';
import { useUser } from '@zep/lib/auth';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@zep/ui';

export const MyMenuDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useUser();

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen} modal={false}>
      <DropdownMenuTrigger
        className="hidden max-w-[120px] overflow-hidden whitespace-nowrap rounded-[8px] py-[10px] pl-xs pr-2xs text-[14px] hover:bg-[#3E3E47]/[.04] focus:outline-none md:flex"
        tabIndex={-1}>
        <div className="max-w-full truncate">{user?.username}</div>
        <img
          className="w-[20px]"
          src="/assets/icon_caret_down_gray.svg"
          alt="more"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        className="shadow-[0_4px_8px_2px_rgba(28, 27, 31, 0.12)] max-h-[200px] min-w-[148px] rounded-[8px] px-[8px] py-3xs text-[13px]">
        <DropdownMenuGroup>
          <DropdownMenuItem onClick={() => setIsOpen(false)} className={'p-0'}>
            <MyQuizButton.Normal />
          </DropdownMenuItem>
          <DropdownMenuItem className={'p-0'}>
            <EditNicknameButton type={'drawer'} />
          </DropdownMenuItem>
          <DropdownMenuItem className={'p-0'}>
            <LogOutButton />
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
