import type { SVGProps } from 'react';
import * as React from 'react';
const SvgCaretUpdown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path fill="#5D5E69" d="m17 10-5-6-5 6zM17 13l-5 6-5-6z" />
  </svg>
);
export default SvgCaretUpdown;
