import type { SVGProps } from 'react';
import * as React from 'react';
const SvgCodeQr = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      fillRule="evenodd"
      d="M4.8 4.8v4.5h4.5V4.8zM3.9 3a.9.9 0 0 0-.9.9v6.3a.9.9 0 0 0 .9.9h6.3a.9.9 0 0 0 .9-.9V3.9a.9.9 0 0 0-.9-.9zM14.7 4.8v4.5h4.5V4.8zM13.8 3a.9.9 0 0 0-.9.9v6.3a.9.9 0 0 0 .9.9h6.3a.9.9 0 0 0 .9-.9V3.9a.9.9 0 0 0-.9-.9zM4.8 14.7v4.5h4.5v-4.5zm-.9-1.8a.9.9 0 0 0-.9.9v6.3a.9.9 0 0 0 .9.9h6.3a.9.9 0 0 0 .9-.9v-6.3a.9.9 0 0 0-.9-.9z"
      clipRule="evenodd"
    />
    <path
      fill="#5D5E69"
      d="M15.6 14.7a.9.9 0 0 1-.9.9h-.9a.9.9 0 0 1-.9-.9v-.9a.9.9 0 0 1 .9-.9h.9a.9.9 0 0 1 .9.9zM18.3 17.4a.9.9 0 0 1-.9.9h-.9a.9.9 0 0 1-.9-.9v-.9a.9.9 0 0 1 .9-.9h.9a.9.9 0 0 1 .9.9zM21 14.7a.9.9 0 0 1-.9.9h-.9a.9.9 0 0 1-.9-.9v-.9a.9.9 0 0 1 .9-.9h.9a.9.9 0 0 1 .9.9zM15.6 20.1a.9.9 0 0 1-.9.9h-.9a.9.9 0 0 1-.9-.9v-.9a.9.9 0 0 1 .9-.9h.9a.9.9 0 0 1 .9.9zM21 20.1a.9.9 0 0 1-.9.9h-.9a.9.9 0 0 1-.9-.9v-.9a.9.9 0 0 1 .9-.9h.9a.9.9 0 0 1 .9.9z"
    />
  </svg>
);
export default SvgCodeQr;
