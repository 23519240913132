import { NoSSR, QuizLogo } from '@zep/components';
import { HeaderContainer } from '@zep/layout/Header/HeaderContainer.tsx';
import {
  MobileDrawer,
  TriggerContent,
} from '@zep/layout/Header/MobileDrawer.tsx';
import {
  EnterCodeButton,
  ExploreButton,
  HeaderAdminButton,
  PricingButton,
  UserGuideButton,
} from '@zep/layout/Header/PageNavigatorButton.tsx';
import {
  AuthButton,
  CreateNewQuizButton,
} from '@zep/layout/Header/UserManagementButton.tsx';
import { zepAnalytics } from '@zep/utils';
import { useRouter } from 'next/router';

export default function Header() {
  return (
    <HeaderContainer className="m-auto flex items-center justify-between px-md py-sm md:px-[24px] lg:px-[40px]">
      <div className="flex items-center gap-[24px]">
        <HomeLogo location={'header'} />
        <div className={'gap flex gap-sm'}>
          <PricingButton />
          <ExploreButton.Normal />
          <UserGuideButton.Normal />
          <HeaderAdminButton />
        </div>
      </div>
      <div className="flex items-center gap-2xs">
        <ExploreButton.Icon />
        <AuthButton />
        <EnterCodeButton type={'normal'} />
        <NoSSR fallback={<TriggerContent />}>
          <MobileDrawer />
        </NoSSR>
        <CreateNewQuizButton type={'normal'} />
      </div>
    </HeaderContainer>
  );
}

export const ButtonHeader =
  'bg-background text-nowrap border-none text-[#5D5E69] hover:bg-[#3E3E47]/[.04]';

export const HomeLogo = ({
  location,
  isSmall,
}: {
  location: 'header' | 'play';
  isSmall?: boolean;
}) => {
  const router = useRouter();

  const goHome = () => {
    trackAnalytics('home', location);

    router.push('/landing');
  };

  if (isSmall) {
    return (
      <img
        className={'size-[28px] cursor-pointer lg:size-[32px]'}
        onClick={goHome}
        src="/assets/logo_small_q.svg"
        alt=""
      />
    );
  }

  return <QuizLogo onClick={goHome} />;
};

export const trackAnalytics = (to: string, loc: string, userName?: string) => {
  zepAnalytics.track(`enter_${to}`, {
    location: `${loc}`,
    ...(to === 'create' && { name: userName }),
  });
};
