import { createMutation } from 'react-query-kit';
import { UserType } from '@zep/types';

import { apiClient } from '../utils/api';

import { ApiRoute } from './apiRoute.const.ts';

type ChangeUsernameVariables = {
  username: string;
};

type ChangeUsernameResponse = {
  status: string;
  statusNum: number;
};

export const useChangeUsername = createMutation<
  ChangeUsernameResponse,
  ChangeUsernameVariables
>({
  mutationFn: (variables: ChangeUsernameVariables) =>
    apiClient
      .patch<ChangeUsernameResponse>(ApiRoute.API_EDIT_ME, {
        name: variables.username,
      })
      .then(res => res.data),
});

type UseSetUserDetailVariables = {
  type: UserType;
  countryCode: string;
  userInfo: string;
  termsAgreement: boolean;
  privacyPolicyAgreement: boolean;
  marketingUsageAgreement: boolean;
};

export const useSetUserDetail = createMutation<
  { status: string; statusNum: number },
  UseSetUserDetailVariables
>({
  mutationFn: variables =>
    apiClient
      .patch<{ status: string; statusNum: number }>(ApiRoute.API_MEMBER_INFO, {
        ...variables,
      })
      .then(res => res.data),
});
