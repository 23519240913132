import type { SVGProps } from 'react';
import * as React from 'react';
const SvgMobile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      fillRule="evenodd"
      d="M8 4a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1zM5.879 2.879A3 3 0 0 1 8 2h8a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H8a3 3 0 0 1-3-3V5a3 3 0 0 1 .879-2.121M12 16a1 1 0 0 1 1 1v.01a1 1 0 1 1-2 0V17a1 1 0 0 1 1-1"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMobile;
