import type { SVGProps } from 'react';
import * as React from 'react';
const SvgTshirt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      fillRule="evenodd"
      d="M6.293 4.293A1 1 0 0 1 7 4h2.55a1 1 0 0 1 .98.801 1.5 1.5 0 0 0 2.94 0A1 1 0 0 1 14.45 4H17a1 1 0 0 1 .707.293l5 5a1 1 0 0 1 0 1.414l-3 3a1 1 0 0 1-1.414 0L18 13.414V20a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-6.586l-.293.293a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 0-1.414zM7.414 6l-4 4L5 11.586l1.293-1.293A1 1 0 0 1 8 11v8h8v-8a1 1 0 0 1 1.707-.707L19 11.586 20.586 10l-4-4h-1.423a3.5 3.5 0 0 1-6.326 0z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTshirt;
