import clsx, { type ClassValue } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';
const twMerge = extendTailwindMerge({
  extend: {
    theme: {
      spacing: [
        '4xs',
        '3xs',
        '2xs',
        'xs',
        'sm',
        'md',
        'lg',
        'xl',
        '2xl',
        '3xl',
        '4xl',
        'none',
      ],
    },
    classGroups: {
      'font-size': [
        {
          'text-display': ['xl', 'lg', 'md', 'sm'],
          'text-heading': ['xl', 'lg', 'md', 'sm'],
          'text-body': ['xl', 'lg', 'md', 'sm'],
          'text-caption': ['xl', 'lg', 'md', 'sm'],
        },
      ],
    },
  },
});

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
