import type { SVGProps } from 'react';
import * as React from 'react';
const SvgPersonStackFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      d="M4 8a6 6 0 1 1 12 0A6 6 0 0 1 4 8M3 22a1 1 0 0 1-1-1 5 5 0 0 1 5-5h6a5 5 0 0 1 5 5 1 1 0 0 1-1 1zM18.243 3.757a1 1 0 1 0-1.415 1.415 4 4 0 0 1 0 5.656 1 1 0 0 0 1.415 1.415 6 6 0 0 0 0-8.486M18.743 16.03a1 1 0 1 0-.486 1.94c.672.168 1.082.57 1.349 1.102.282.565.394 1.27.394 1.928a1 1 0 1 0 2 0c0-.843-.138-1.887-.606-2.822-.483-.967-1.324-1.816-2.651-2.148"
    />
  </svg>
);
export default SvgPersonStackFill;
