import type { SVGProps } from 'react';
import * as React from 'react';
const SvgCheckFlower = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      fillRule="evenodd"
      d="M12.01 4.012a1.2 1.2 0 0 0-.85.354l-.002.002-.7.7-.002.002A3.2 3.2 0 0 1 8.2 6h-1A1.2 1.2 0 0 0 6 7.2v1c-.001.845-.336 1.656-.931 2.256l-.002.002-.7.7-.002.002a1.2 1.2 0 0 0 0 1.701l.002.002.702.702c.595.6.93 1.41.93 2.255v1a1.2 1.2 0 0 0 1.2 1.2h1c.845 0 1.656.336 2.256.931l.002.002.702.702a1.2 1.2 0 0 0 1.701 0l.002-.002.7-.7.002-.002a3.2 3.2 0 0 1 2.255-.93h1a1.2 1.2 0 0 0 1.2-1.2v-1c0-.845.336-1.656.931-2.256l.002-.002.7-.7.002-.002a1.2 1.2 0 0 0 0-1.701l-.002-.002-.7-.7-.001-.002A3.2 3.2 0 0 1 18.02 8.2v-1a1.2 1.2 0 0 0-1.2-1.2h-1a3.2 3.2 0 0 1-2.255-.93l-.002-.002-.7-.7-.002-.002a1.2 1.2 0 0 0-.85-.354m-1.228-1.755a3.2 3.2 0 0 1 3.496.697l.002.001.696.697c.225.223.53.348.844.348h1a3.2 3.2 0 0 1 3.2 3.2v1c0 .316.126.62.349.845l.697.696v.001a3.2 3.2 0 0 1 0 4.536v.002l-.696.696h-.001a1.2 1.2 0 0 0-.349.845v1a3.2 3.2 0 0 1-3.2 3.2h-.999a1.2 1.2 0 0 0-.845.348l-.696.697h-.002a3.2 3.2 0 0 1-4.536 0l-.697-.696a1.2 1.2 0 0 0-.845-.35h-1a3.2 3.2 0 0 1-3.2-3.2v-1a1.2 1.2 0 0 0-.348-.844l-.697-.696v-.002a3.2 3.2 0 0 1 0-4.535V9.74l.696-.696h.001A1.2 1.2 0 0 0 4 8.2v-1A3.2 3.2 0 0 1 7.2 4h1a1.2 1.2 0 0 0 .844-.348h.001l.696-.697h.002a3.2 3.2 0 0 1 1.039-.698"
      clipRule="evenodd"
    />
    <path
      fill="#5D5E69"
      d="M15.732 9.203a.8.8 0 0 1 .066 1.129l-4.266 4.8a.8.8 0 0 1-1.196 0l-2.133-2.4a.8.8 0 0 1 1.195-1.063l1.536 1.727 3.669-4.127a.8.8 0 0 1 1.129-.066"
    />
  </svg>
);
export default SvgCheckFlower;
