import type { SVGProps } from 'react';
import * as React from 'react';
const SvgGearFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      fillRule="evenodd"
      d="M19.97 12.001a1 1 0 0 0 .25.66l1.35 1.44a1 1 0 0 1 .12 1.17l-2 3.46a1 1 0 0 1-1.07.48l-1.88-.38a1 1 0 0 0-1.15.66l-.61 1.83a1 1 0 0 1-.95.68h-4a1 1 0 0 1-1-.68l-.61-1.83a1 1 0 0 0-1.15-.66l-1.88.38a1 1 0 0 1-1.07-.48l-2-3.46a1 1 0 0 1 .1-1.17l1.27-1.44a1 1 0 0 0 0-1.32l-1.27-1.44a1 1 0 0 1-.1-1.17l2-3.46a1 1 0 0 1 1-.48l1.93.38a1 1 0 0 0 1.15-.66l.56-1.83a1 1 0 0 1 1-.68h4a1 1 0 0 1 .95.68l.61 1.83a1 1 0 0 0 1.15.66l1.88-.38a1 1 0 0 1 1.07.48l2 3.46a1 1 0 0 1-.12 1.17l-1.28 1.44a1 1 0 0 0-.25.66M12 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgGearFill;
