import type { SVGProps } from 'react';
import * as React from 'react';
const SvgRectangleStack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      fillRule="evenodd"
      d="M2 5a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3h2a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-3H4a2 2 0 0 1-2-2zm2 0h12v9H4zm4 11v3h12v-9h-2v4a2 2 0 0 1-2 2z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgRectangleStack;
