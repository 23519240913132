import type { SVGProps } from 'react';
import * as React from 'react';
const SvgMessageDotsSlash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      d="M2.707 2.293a1 1 0 0 0 0 1.414l16.97 16.97a1 1 0 0 0 1.415-1.413L4.122 2.293a1 1 0 0 0-1.415 0M2 6c0-.28.058-.547.162-.789L4 7.05V17h5a1 1 0 0 1 .707.293L12 19.586l2.268-2.268L15.951 19h-.537l-2.707 2.707a1 1 0 0 1-1.414 0L8.586 19H4a2 2 0 0 1-2-2zM7.995 4l2 2H20v10.005l1.822 1.822c.114-.252.178-.532.178-.827V6a2 2 0 0 0-2-2z"
    />
    <path
      fill="#5D5E69"
      d="m14.717 10.722 2.06 2.06a1.5 1.5 0 1 0-2.06-2.06M6.5 11.5c0-.53.274-.995.689-1.262l2.073 2.073A1.499 1.499 0 0 1 6.5 11.5"
    />
  </svg>
);
export default SvgMessageDotsSlash;
