import type { SVGProps } from 'react';
import * as React from 'react';
const SvgClipboardCheckFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      d="M9 3a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1z"
    />
    <path
      fill="#5D5E69"
      fillRule="evenodd"
      d="M4 5a2 2 0 0 1 2-2h2v3c0 .5.5 1 1 1h6c.5 0 1-.5 1-1V3h2a2 2 0 0 1 2 2v15a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm11.707 7.207a1 1 0 0 0-1.414-1.414L11 14.086l-1.293-1.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgClipboardCheckFill;
