import type { SVGProps } from 'react';
import * as React from 'react';
const SvgSlashCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      fillRule="evenodd"
      d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2M7.331 5.503a8 8 0 0 1 11.166 11.166zM5.865 6.865a8 8 0 0 0 11.27 11.27z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSlashCircle;
