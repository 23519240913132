import type { SVGProps } from 'react';
import * as React from 'react';
const SvgEye = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path fill="#5D5E69" d="M12 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8" />
    <path
      fill="#5D5E69"
      fillRule="evenodd"
      d="M12 5c3.903 0 7.736 2.236 9.894 6.553a1 1 0 0 1 0 .894C19.736 16.764 15.904 19 12 19s-7.736-2.236-9.894-6.553a1 1 0 0 1 0-.894C4.264 7.236 8.096 5 12 5m-7.87 7c1.868 3.38 4.901 5 7.87 5s6.002-1.62 7.87-5C18.002 8.62 14.97 7 12 7s-6.002 1.62-7.87 5"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgEye;
