import NiceModal from '@ebay/nice-modal-react';
import { NameEditModal } from '@zep/components/modals/NameEditModal.tsx';
import { MyMenuDropdown } from '@zep/layout/Header/MyMenuDropdown.tsx';
import { useUser } from '@zep/lib/auth';
import { Button } from '@zep/ui';
import { cn } from '@zep/utils';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { ButtonHeader, trackAnalytics } from './';

type HeaderButtonProps = { type: 'drawer' | 'normal' };

export const AuthButton = () => {
  const { user, isInitialization } = useUser();

  if (!isInitialization) {
    return null;
  }

  if (user) {
    return <MyMenuDropdown />;
  } else {
    return <LogInButton type={'normal'} />;
  }
};

export const LogInButton = ({ type }: HeaderButtonProps) => {
  const { t } = useTranslation();

  if (type === 'drawer') {
    return (
      <Button
        asChild
        className={cn(ButtonHeader, 'text-[15px] flex gap-3xs justify-start')}>
        <Link href={'/login'} onClick={() => trackAnalytics('login', 'Header')}>
          <img src="/assets/icon_login.svg" alt="login-icon" />
          {t('header.login')}
        </Link>
      </Button>
    );
  }

  return (
    <Button
      asChild
      className={cn(ButtonHeader, 'px-xs py-[10px] hidden md:flex')}>
      <Link href={'/login'} onClick={() => trackAnalytics('login', 'Header')}>
        {t('header.login')}
      </Link>
    </Button>
  );
};

export const LogOutButton = ({ isDrawerOpen }: { isDrawerOpen?: boolean }) => {
  const { t } = useTranslation();
  const { logOut } = useUser();

  if (isDrawerOpen) {
    return (
      <Button
        className={ButtonHeader}
        onClick={() => {
          logOut();
          trackAnalytics('logout', 'Header');
        }}>
        {t('header.logout')}
      </Button>
    );
  }

  return (
    <Button
      variant="destructive"
      className="flex h-[36px] min-h-[36px] w-full justify-start gap-3xs p-0 px-2xs py-[10px]"
      onClick={() => {
        logOut();
        trackAnalytics('logout', 'Header');
      }}>
      <img src="/assets/icon_out_red.svg" alt="logout" />{' '}
      <div className="text-[#FF4848]">{t('header.logout')}</div>
    </Button>
  );
};

export const EditNicknameButton = (props: {
  type: 'mobile-drawer' | 'drawer';
}) => {
  const { t } = useTranslation();
  const { user } = useUser();

  const handleNameEditModalOpen = () => {
    NiceModal.show(NameEditModal, {
      initialName: user?.username,
    });
  };

  if (props.type === 'mobile-drawer') {
    return (
      <Button
        className={cn(
          ButtonHeader,
          'gap-xs',
          'max-w-[200px] overflow-hidden truncate px-0 py-[12.5px] text-[15px]',
        )}
        onClick={handleNameEditModalOpen}>
        <div className="max-w-full truncate">{user?.username}</div>
        <img
          src="/assets/icon_pen_gray_line.svg"
          className="size-[18px]"
          alt="edit"
        />
      </Button>
    );
  }

  return (
    <Button
      className={cn(
        ButtonHeader,
        'flex h-[36px] p-0 min-h-[36px] w-full justify-start gap-3xs px-2xs py-[10px]',
      )}
      onClick={handleNameEditModalOpen}>
      <img src="/assets/icon_pen_gray_line.svg" alt="edit" />
      {t('nameChangeModal.title')}
    </Button>
  );
};

export const CreateNewQuizButton = ({ type }: HeaderButtonProps) => {
  const { t } = useTranslation();
  const { user } = useUser();

  if (type === 'drawer') {
    return (
      <Button
        asChild
        className="border-[rgba(28, 27, 31, 0.04)] flex size-full h-auto w-full justify-center gap-3xs border-[1px] bg-primary-foreground
        p-0 py-[13.5px] text-[15px] text-text-strong hover:bg-[#E7E4FF]
        ">
        <Link
          href="/build"
          onClick={() =>
            trackAnalytics('create', 'Header', user?.username || '')
          }>
          <img
            src="/assets/icon_plus_primary.png"
            className="size-[24px]"
            alt="plusicon"
          />
          {t('header.create.button')}
        </Link>
      </Button>
    );
  }

  return (
    <Button
      asChild
      className="hidden bg-[#6758FF] p-0 text-white hover:bg-text-strong md:flex">
      <Link
        href="/build"
        onClick={() => trackAnalytics('create', 'Header', user?.username || '')}
        className="flex gap-3xs px-xs py-[10px]">
        <img
          src="/assets/icon_plus.png"
          className="size-[18px]"
          alt="plusicon"
        />
        {t('header.create.button')}
      </Link>
    </Button>
  );
};
