import { PropsWithChildren, useEffect } from 'react';
import { usePreservedCallback } from '@toss/react';
import { useUser } from '@zep/lib/auth';
import { useCheckAuthenticated } from '@zep/lib/auth/hooks/useCheckAuthenticated.ts';
import { useRouter } from 'next/router';

export const AuthWrapper = (
  props: PropsWithChildren<{
    redirectPath: string;
    callbackUrl: string;
  }>,
) => {
  const router = useRouter();

  useCheckAuthenticated(() => {
    router.push(
      {
        pathname: props.redirectPath,
        query: { callbackUrl: props.callbackUrl },
      },
      undefined,
    );
  });

  return props.children;
};

export const AuthAdminWrapper = (
  props: PropsWithChildren<{
    redirectPath: string;
    callbackUrl: string;
  }>,
) => {
  const router = useRouter();
  const { user, isInitialization, isAuthenticated } = useUser();

  const goToPage = usePreservedCallback(() => {
    router.push(
      {
        pathname: props.redirectPath,
        query: { callbackUrl: props.callbackUrl },
      },
      undefined,
    );
  });

  useEffect(() => {
    if (isInitialization) {
      if (!isAuthenticated || !user?.admin) {
        goToPage();
      }
    }
  }, [user?.admin, goToPage, isAuthenticated, isInitialization]);

  return isInitialization ? props.children : null;
};
