import type { SVGProps } from 'react';
import * as React from 'react';
const SvgPictureFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    viewBox="0 0 48 48"
    {...props}>
    <path
      fill="#D5D9E0"
      d="M31 20C32.6569 20 34 18.6569 34 17C34 15.3431 32.6569 14 31 14C29.3431 14 28 15.3431 28 17C28 18.6569 29.3431 20 31 20Z"
    />
    <path
      fill="#D5D9E0"
      d="M6 10C6 7.79086 7.79086 6 10 6H38C40.2091 6 42 7.79086 42 10V38C42 40.2091 40.2091 42 38 42H10C7.79086 42 6 40.2091 6 38V10ZM38 10H10V25.8388L16.7507 20.4383C17.4811 19.8539 18.519 19.8539 19.2495 20.4383L27.8516 27.32L30.5859 24.5858C31.3669 23.8047 32.6332 23.8047 33.4143 24.5858L38 29.1715V10Z"
    />
  </svg>
);
export default SvgPictureFill;
