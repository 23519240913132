import type { SVGProps } from 'react';
import * as React from 'react';
const SvgCaretBigUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path fill="#5D5E69" d="M20 16 12 6 4 16z" />
  </svg>
);
export default SvgCaretBigUp;
