import type { SVGProps } from 'react';
import * as React from 'react';
const SvgWarninglightFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      fillRule="evenodd"
      d="M12 2a8 8 0 0 0-8 8v8H3a1 1 0 1 0 0 2h18a1 1 0 1 0 0-2h-1v-8a8 8 0 0 0-8-8m0 3.15c-.825 0-2.024.27-3.035 1.09-1.047.848-1.815 2.223-1.815 4.26a.85.85 0 1 0 1.7 0c0-1.563.565-2.438 1.185-2.94A3.3 3.3 0 0 1 12 6.85a.85.85 0 0 0 0-1.7"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWarninglightFill;
