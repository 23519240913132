import type { SVGProps } from 'react';
import * as React from 'react';
const SvgDiceFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      d="M17.7 3a3.3 3.3 0 0 1 3.296 3.125L21 6.3v11.4a3.3 3.3 0 0 1-3.125 3.296L17.7 21H6.3a3.3 3.3 0 0 1-3.295-3.125L3 17.7V6.3a3.3 3.3 0 0 1 3.125-3.295L6.3 3zm-2.55 10.8a1.35 1.35 0 1 0 0 2.7 1.35 1.35 0 0 0 0-2.7M12 10.65a1.35 1.35 0 1 0 0 2.7 1.35 1.35 0 0 0 0-2.7M8.85 7.5a1.35 1.35 0 1 0 0 2.7 1.35 1.35 0 0 0 0-2.7"
    />
  </svg>
);
export default SvgDiceFill;
