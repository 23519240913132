import NiceModal from '@ebay/nice-modal-react';

import {
  CustomAlert,
  CustomAlertProps,
} from '../components/modals/CustomAlert.tsx';

export const useAlert = () => {
  return (content: CustomAlertProps, resolveAction?: () => void) =>
    NiceModal.show(CustomAlert, content).then(resolveAction);
};
