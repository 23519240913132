import { isSafari } from 'react-device-detect';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useInputState } from '@toss/react';
import { useTranslation } from 'next-i18next';

import { useEnterCode } from '../../api/quiz';
import { zepAnalytics } from '../../utils';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogPanel,
  DialogTitle,
} from '../ui/dialog';
import { TextField } from '../ui/TextField';

function _CodeEnter() {
  const modal = useModal();
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useInputState('');
  const { mutateAsync } = useEnterCode();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!inputValue?.trim()) {
      alert(t('codeEnterModal.error.input'));
      return;
    }

    try {
      const { data, status } = await mutateAsync({ enterCode: inputValue });
      if (status === 'FAILED') {
        alert(t('codeEnterModal.error.notFound'));
        return;
      }

      zepAnalytics.track('code_complete');
      zepAnalytics.track('enter_play', {
        location: 'enter_code',
        spaceId: data.spaceHashId,
      });
      if (isSafari) {
        location.href = `/play/${data.entryMapHashId}`;
      } else {
        window.open(`/play/${data.entryMapHashId}`, '_blank');
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Dialog onClick={modal.remove}>
      <DialogPanel className="max-w-[720px]">
        <DialogHeader onClose={modal.remove}>
          <DialogTitle>{t('codeEnterModal.title')}</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              isBig
              autoFocus
              maxLength={10}
              placeholder={t('codeEnterModal.placeholder')}
              value={inputValue}
              onChange={setInputValue}
              type="number"
              className="w-full [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
            />
          </DialogContent>
          <DialogFooter className="flex justify-end pt-0">
            <button
              type="submit"
              className="min-w-[140px] rounded-[6px] bg-primary px-sm py-xs text-body-lg font-semibold leading-[1.4] text-white">
              {t('global.enter.button')}
            </button>
          </DialogFooter>
        </form>
      </DialogPanel>
    </Dialog>
  );
}

export const CodeEnter = NiceModal.create(_CodeEnter);
