import {
  DialogTitle as _DialogTitle,
  DialogTitleProps,
} from '@headlessui/react';
import { cn } from '@zep/utils';

import S from './Dialog.module.scss';

export const DialogTitle = (props: DialogTitleProps<'div'>) => {
  const { className, children } = props;
  return (
    <_DialogTitle {...props} className={cn(S.title, className as string)}>
      {children}
    </_DialogTitle>
  );
};
