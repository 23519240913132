import type { SVGProps } from 'react';
import * as React from 'react';
const SvgBatteryFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      fillRule="evenodd"
      d="M9 3a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1h1a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1zm4.052 9.273c0 .11.09.2.2.2h1.816c.163 0 .258.184.163.316l-4.221 5.905a.2.2 0 0 1-.363-.117V13.42a.2.2 0 0 0-.2-.2H8.631a.2.2 0 0 1-.163-.317l4.221-5.904a.2.2 0 0 1 .363.116z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBatteryFill;
