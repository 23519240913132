import ReactGA from 'react-ga4';
import {
  Identify,
  identify,
  setUserId,
  track,
} from '@amplitude/analytics-browser';
import isMobile from 'is-mobile';
import UAParser, { UAParserInstance } from 'ua-parser-js';

import { StatefulPQueue } from '../common';

const queue = new StatefulPQueue({ autoStart: false });

const parser = new UAParser() as UAParserInstance;

type UserProperties = Record<string, boolean | string | null>;

function setUserProperties(records: UserProperties) {
  const identifyEvent = new Identify();
  Object.entries(records).forEach(([key, value]) => {
    identifyEvent.set(key, String(value));
  });

  return identify(identifyEvent).promise;
}

const trackEvent = async (...params: Parameters<typeof track>) => {
  const [eventName, eventProperties, eventOptions] = params;
  ReactGA.event(String(eventName), eventProperties);
  return track(eventName, eventProperties, eventOptions).promise;
};

export const zepAnalytics: ZepAnalytics = {
  track: async (eventName, eventProperties, eventOptions) => {
    if (!queue.isReady) {
      return queue.add(() =>
        trackEvent(eventName, eventProperties, eventOptions),
      );
    } else {
      return trackEvent(eventName, eventProperties, eventOptions);
    }
  },

  setAmplitudeUserId: (userId: string) => {
    setUserId(userId);
    setUserProperties({ IS_LOGIN: true });
  },

  resetAmplitudeUserId: () => {
    setUserId(undefined);
    setUserProperties({ IS_LOGIN: false });
  },

  setDefaultUserProperties: () => {
    const records = {
      BROWSER: String(parser.getBrowser().name),
      OS: String(parser.getOS().name),
      MOBILE: String(isMobile()),
    };
    setUserProperties(records).then(() => {
      queue.setReady(true).start();
    });
  },
};

type ZepAnalytics = {
  track: (
    ...params: Parameters<typeof track>
  ) => ReturnType<typeof track>['promise'];
  setAmplitudeUserId: (userId: string) => void;
  resetAmplitudeUserId: () => void;
  setDefaultUserProperties: () => void;
};
