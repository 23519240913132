import { useTranslation } from 'next-i18next';

import { validateFile, ValidateFileParams } from '../utils/file';

import { useAlert } from './useAlert.ts';

export const useFileValidateFile = () => {
  const alert = useAlert();
  const { t } = useTranslation();

  return (params: ValidateFileParams & { onConfirm?: () => void }) => {
    const result = validateFile(params);

    if (!result.error) return true;

    if (result.type == 'size') {
      alert({
        type: 'alert',
        title: t('build.imageUpload.limitSize', {
          size: params.validation.mbSize + 'MB',
        }),
      }).then(params.onConfirm);
      return false;
    }

    alert({
      type: 'alert',
      title: t('build.imageUpload.confirmExtension'),
      content: t('build.imageUpload.limitExtension', {
        extension: params.validation.extensions.join(', '),
      }),
    }).then(params.onConfirm);
    return false;
  };
};
