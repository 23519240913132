import type { SVGProps } from 'react';
import * as React from 'react';
const SvgMessageDots = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      d="M2 6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2h-4.586l-2.707 2.707a1 1 0 0 1-1.414 0L8.586 19H4a2 2 0 0 1-2-2zm18 0H4v11h5a1 1 0 0 1 .707.293L12 19.586l2.293-2.293A1 1 0 0 1 15 17h5z"
    />
    <path
      fill="#5D5E69"
      d="M13.5 11.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M17.5 11.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M9.5 11.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"
    />
  </svg>
);
export default SvgMessageDots;
