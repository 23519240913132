import { useContext } from 'react';

import { UserContext } from './UserContext.tsx';

export const useUser = () => {
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new Error('UserContext not found');
  }

  return userContext;
};
