import {
  ComponentPropsWithoutRef,
  forwardRef,
  LegacyRef,
  useState,
} from 'react';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import { useBodyAttribute } from '@zep/hooks/useBodyAttribute.ts';
import styles from '@zep/layout/Header/Header.module.scss';
import { HomeLogo } from '@zep/layout/Header/Header.tsx';
import {
  EnterCodeButton,
  ExploreButton,
  MyQuizButton,
  PricingButton,
  UserGuideButton,
} from '@zep/layout/Header/PageNavigatorButton.tsx';
import {
  CreateNewQuizButton,
  EditNicknameButton,
  LogInButton,
  LogOutButton,
} from '@zep/layout/Header/UserManagementButton.tsx';
import { useUser } from '@zep/lib/auth';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@zep/ui';
import { cn } from '@zep/utils';

interface TriggerContentProps extends ComponentPropsWithoutRef<'img'> {}

export const TriggerContent = forwardRef<HTMLImageElement, TriggerContentProps>(
  (props: ComponentPropsWithoutRef<'img'>, ref) => {
    return (
      <div className="w-[40px] md:hidden">
        <img
          src="/assets/icon_menu.svg"
          className="cursor-pointer p-xs hover:rounded-[8px] hover:bg-[#3E3E470A] md:hidden"
          {...props}
          ref={ref as LegacyRef<HTMLImageElement>}
          alt="menuicon"
        />
      </div>
    );
  },
);
TriggerContent.displayName = 'TriggerContent';

export const MobileDrawer = () => {
  const { user } = useUser();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isStickBannerShow =
    useBodyAttribute('data-is-stick-banner-show') === 'true';

  const toggleOpen = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 z-50 bg-surfaceDimmed"
          onClick={() => setIsOpen(false)}></div>
      )}
      <Drawer
        open={isOpen}
        onOpenChange={setIsOpen}
        direction="top"
        modal={false}>
        <DrawerTrigger asChild>
          <TriggerContent onClick={() => setIsOpen(true)} />
        </DrawerTrigger>
        <DrawerContent
          aria-describedby={undefined}
          className={cn(
            styles.header_container,
            ' bg-white z-40 inset-x-0 top-[64px] flex h-fit rounded-none shadow-[0_12px_16px_4px_#1c1b1f1f] focus:outline-none md:hidden',
            `${!isStickBannerShow && 'top-0'}`,
          )}>
          <DrawerHeader className="flex items-center justify-between border-b-[1px_solid_#ededed] px-md py-sm">
            <VisuallyHidden.Root>
              <DrawerTitle />
            </VisuallyHidden.Root>
            <HomeLogo location={'header'} />
            <DrawerClose className="cursor-pointer rounded-[8px] p-[8px] hover:bg-[#3E3E47]/[.04]">
              <img
                src="/assets/icon_close_gray.svg"
                className="size-[24px]"
                alt="closeicon"
              />
            </DrawerClose>
          </DrawerHeader>
          <div className="flex flex-col gap-md p-md">
            <PricingButton
              className={'block w-full pl-4'}
              onClick={toggleOpen}
            />
            <ExploreButton.Drawer onClick={toggleOpen} />
            <UserGuideButton.Drawer />
          </div>
          <div className="my-sm h-4xs w-full bg-muted" />
          <DrawerFooter className="mt-0 pt-0">
            {user ? (
              <div className="flex flex-col gap-sm">
                <div className="align-center flex justify-between py-[20px]">
                  <EditNicknameButton type={'mobile-drawer'} />
                  <LogOutButton isDrawerOpen={isOpen} />
                </div>
                <MyQuizButton.Drawer onClick={toggleOpen} />
              </div>
            ) : (
              <LogInButton type={'drawer'} />
            )}
            <CreateNewQuizButton type={'drawer'} />
            <EnterCodeButton type={'drawer'} />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
