import type { SVGProps } from 'react';
import * as React from 'react';
const SvgCreditCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#FFD600"
      d="M2 7a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2z"
    />
    <path fill="#000" fillOpacity={0.4} d="M2 8h20v2H2z" />
    <path
      fill="#fff"
      d="M4 13a1 1 0 0 1 1-1h11a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1M4 16a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1M10 16a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2h-1a1 1 0 0 1-1-1"
    />
    <path fill="#E4A01D" d="M2 8h20v2H2z" />
    <path
      fill="#fff"
      d="M4 13a1 1 0 0 1 1-1h11a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1M4 16a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1M10 16a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2h-1a1 1 0 0 1-1-1"
    />
  </svg>
);
export default SvgCreditCard;
