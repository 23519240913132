import type { SVGProps } from 'react';
import * as React from 'react';
const SvgLocationFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      fillRule="evenodd"
      d="M12 2c-4.4 0-8 3.6-8 8 0 5.067 6.163 10.75 7.171 11.68q.1.091.129.12c.2.1.5.2.7.2s.5-.1.7-.2q.03-.028.129-.12C13.837 20.75 20 15.067 20 10c0-4.4-3.6-8-8-8m0 5a3 3 0 1 0 0 6 3 3 0 0 0 0-6"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLocationFill;
