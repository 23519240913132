import type { SVGProps } from 'react';
import * as React from 'react';
const SvgQuestionmarkSmallBubble = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      fillRule="evenodd"
      d="M11.5 4.004a7.5 7.5 0 0 1 5.915 12.111 1 1 0 0 0-.113 1.049l.874 1.819-2.798-.751a1 1 0 0 0-.685.06A7.5 7.5 0 1 1 11.5 4.004m9.5 7.5a9.5 9.5 0 1 0-5.798 8.751l3.507.942c1.24.332 2.297-.942 1.741-2.098l-1.086-2.263A9.46 9.46 0 0 0 21 11.504"
      clipRule="evenodd"
    />
    <path
      fill="#5D5E69"
      d="M9.854 9.132c.134-.807.884-1.306 1.777-1.263.427.02.803.171 1.062.413.243.227.44.586.44 1.163 0 .661-.225.934-.459 1.09-.3.2-.781.31-1.407.31a.933.933 0 0 0-.934.933v.933a.933.933 0 0 0 1.867 0v-.068c.518-.081 1.046-.246 1.51-.555.816-.544 1.29-1.438 1.29-2.643 0-1.057-.386-1.923-1.033-2.527-.63-.59-1.45-.876-2.248-.914-1.556-.074-3.374.827-3.706 2.82a.933.933 0 0 0 1.841.308M9.867 15.978a1.4 1.4 0 1 1 2.8 0 1.4 1.4 0 0 1-2.8 0"
    />
  </svg>
);
export default SvgQuestionmarkSmallBubble;
