import type { SVGProps } from 'react';
import * as React from 'react';
const SvgOptionVertical = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      d="M14 12a2 2 0 1 0-4 0 2 2 0 0 0 4 0M14 18a2 2 0 1 0-4 0 2 2 0 0 0 4 0M14 6a2 2 0 1 0-4 0 2 2 0 0 0 4 0"
    />
  </svg>
);
export default SvgOptionVertical;
