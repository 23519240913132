import type { SVGProps } from 'react';
import * as React from 'react';
const SvgSpeakerLv1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      fillRule="evenodd"
      d="M13 4a1 1 0 0 0-1.707-.707L7.586 7H4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h3.586l3.707 3.707A1 1 0 0 0 13 20zM8.707 8.707 11 6.414v11.172l-2.293-2.293A1 1 0 0 0 8 15H5V9h3a1 1 0 0 0 .707-.293"
      clipRule="evenodd"
    />
    <path
      fill="#5D5E69"
      d="M16.707 8.793a1 1 0 1 0-1.414 1.414c.431.432.957.999.957 1.793s-.526 1.361-.957 1.793a1 1 0 0 0 1.414 1.414l.028-.028c.424-.423 1.515-1.511 1.515-3.179s-1.09-2.756-1.515-3.18z"
    />
  </svg>
);
export default SvgSpeakerLv1;
