import type { SVGProps } from 'react';
import * as React from 'react';
const SvgArrowsUpdown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    {...props}>
    <path
      d="M15.6211 13.4321C15.9058 13.1473 15.9058 12.6857 15.6211 12.4009C15.3363 12.1161 14.8746 12.1161 14.5899 12.4009L13.6471 13.3436V5.62484C13.6471 5.22213 13.3207 4.89567 12.918 4.89567C12.5153 4.89567 12.1888 5.22213 12.1888 5.62484L12.1888 13.3436L11.2461 12.4009C10.9613 12.1161 10.4996 12.1161 10.2149 12.4009C9.93011 12.6857 9.93011 13.1473 10.2149 13.4321L12.4024 15.6196C12.5391 15.7563 12.7246 15.8332 12.918 15.8332C13.1114 15.8332 13.2968 15.7563 13.4336 15.6196L15.6211 13.4321Z"
      fill="#5E6066"
    />
    <path
      d="M9.78773 6.56757C10.0725 6.85233 10.0725 7.31401 9.78773 7.59877C9.50298 7.88353 9.04129 7.88353 8.75654 7.59877L7.8138 6.65603L7.8138 14.3748C7.8138 14.7775 7.48734 15.104 7.08464 15.104C6.68193 15.104 6.35547 14.7775 6.35547 14.3748L6.35547 6.65603L5.41273 7.59877C5.12798 7.88353 4.66629 7.88353 4.38154 7.59877C4.09678 7.31401 4.09678 6.85233 4.38154 6.56757L6.56904 4.38007C6.70578 4.24333 6.89125 4.1665 7.08463 4.1665C7.27802 4.1665 7.46349 4.24333 7.60023 4.38007L9.78773 6.56757Z"
      fill="#5E6066"
    />
  </svg>
);
export default SvgArrowsUpdown;
