import { PropsWithChildren } from 'react';
import { isServer } from '@toss/utils';
import { NextPageWithLayout } from 'next';

import { AuthAdminWrapper } from './AuthWrapper.tsx';

export function withPageAuthAdmin<P extends object>(
  PageComponent: NextPageWithLayout<P>,
  callbackUrl: string = '',
  redirectPath: string = '/login',
) {
  return Object.assign(
    (props: PropsWithChildren<P>) =>
      isServer() ? (
        <PageComponent {...props} />
      ) : (
        <AuthAdminWrapper redirectPath={redirectPath} callbackUrl={callbackUrl}>
          <PageComponent {...props} />
        </AuthAdminWrapper>
      ),
    {
      getLayout: PageComponent.getLayout,
    },
  );
}
