import type { SVGProps } from 'react';
import * as React from 'react';
const SvgAuthority2Color = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#E1E1E1"
      d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12"
    />
    <path
      fill="url(#Authority-2-color_svg__a)"
      d="M21.913 12c0 5.475-4.438 9.913-9.913 9.913S2.087 17.475 2.087 12 6.525 2.087 12 2.087 21.913 6.525 21.913 12"
    />
    <path
      fill="url(#Authority-2-color_svg__b)"
      d="M20.348 12a8.348 8.348 0 1 1-16.696 0 8.348 8.348 0 0 1 16.696 0"
    />
    <path
      fill="#7A7B89"
      d="M9.107 9.497h1.89q.108-.564.38-.888.277-.332.63-.48a1.8 1.8 0 0 1 .704-.148q.42 0 .739.162.325.163.508.445.19.275.19.641 0 .275-.102.529a2 2 0 0 1-.338.543q-.244.29-.685.69-.434.402-1.111.967l-3.767 3.158-.319 1.58h7.108l.346-1.756h-4.27l.028-.127 2.141-1.763q.861-.711 1.437-1.247.575-.543.914-.988.346-.444.495-.867.15-.43.149-.916 0-.797-.427-1.424a2.9 2.9 0 0 0-1.172-.988q-.74-.36-1.687-.36a4.1 4.1 0 0 0-1.857.417 3.5 3.5 0 0 0-1.342 1.142 3.35 3.35 0 0 0-.582 1.678"
    />
    <defs>
      <linearGradient
        id="Authority-2-color_svg__a"
        x1={6.632}
        x2={15.79}
        y1={0}
        y2={24}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#C4C4C4" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="Authority-2-color_svg__b"
        x1={6.632}
        x2={15.79}
        y1={0}
        y2={24}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#EEE" />
        <stop offset={1} stopColor="#E9E9E9" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgAuthority2Color;
