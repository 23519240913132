import type { SVGProps } from 'react';
import * as React from 'react';
const SvgQuestionmarkSmallCircleFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      fillRule="evenodd"
      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10M10.486 9.164c.144-.864.947-1.398 1.904-1.353.458.022.861.184 1.138.443.26.243.472.628.472 1.246 0 .709-.242 1.001-.492 1.168-.322.215-.838.332-1.508.332a1 1 0 0 0-1 1v1a1 1 0 1 0 2 0v-.073c.555-.087 1.12-.264 1.617-.595C15.492 11.75 16 10.792 16 9.5c0-1.132-.413-2.06-1.106-2.707-.677-.632-1.555-.939-2.409-.98-1.668-.079-3.615.887-3.971 3.023a1 1 0 1 0 1.972.328M10.5 16.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgQuestionmarkSmallCircleFill;
