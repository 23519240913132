import type { SVGProps } from 'react';
import * as React from 'react';
const SvgSquareCircleStack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      fillRule="evenodd"
      d="M15 20a5 5 0 1 0 0-10 5 5 0 0 0 0 10m0 2a7 7 0 1 0 0-14 7 7 0 0 0 0 14"
      clipRule="evenodd"
    />
    <path
      fill="#5D5E69"
      d="M4 4h10v5h2V4a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h5v-2H4z"
    />
  </svg>
);
export default SvgSquareCircleStack;
