import type { SVGProps } from 'react';
import * as React from 'react';
const SvgTrash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    viewBox="0 0 14 14"
    {...props}>
    <path
      fill="white"
      d="M4.08366 2.33317C4.08366 1.68884 4.60599 1.1665 5.25033 1.1665H8.75033C9.39466 1.1665 9.91699 1.68884 9.91699 2.33317V3.49984H11.0776C11.0812 3.4998 11.0849 3.4998 11.0885 3.49984H12.2503C12.5725 3.49984 12.8337 3.761 12.8337 4.08317C12.8337 4.40534 12.5725 4.6665 12.2503 4.6665H11.6268L11.1209 11.7496C11.0773 12.3601 10.5693 12.8332 9.95717 12.8332H4.04348C3.4314 12.8332 2.92339 12.3601 2.87978 11.7496L2.37384 4.6665H1.75033C1.42816 4.6665 1.16699 4.40534 1.16699 4.08317C1.16699 3.761 1.42816 3.49984 1.75033 3.49984H2.91218C2.9158 3.4998 2.91941 3.4998 2.92301 3.49984H4.08366V2.33317ZM5.25033 3.49984H8.75033V2.33317H5.25033V3.49984ZM3.54348 4.6665L4.04348 11.6665H9.95717L10.4572 4.6665H3.54348ZM5.83366 5.83317C6.15582 5.83317 6.41699 6.09434 6.41699 6.4165V9.9165C6.41699 10.2387 6.15582 10.4998 5.83366 10.4998C5.51149 10.4998 5.25033 10.2387 5.25033 9.9165V6.4165C5.25033 6.09434 5.51149 5.83317 5.83366 5.83317ZM8.16699 5.83317C8.48916 5.83317 8.75033 6.09434 8.75033 6.4165V9.9165C8.75033 10.2387 8.48916 10.4998 8.16699 10.4998C7.84483 10.4998 7.58366 10.2387 7.58366 9.9165V6.4165C7.58366 6.09434 7.84483 5.83317 8.16699 5.83317Z"
    />
  </svg>
);
export default SvgTrash;
