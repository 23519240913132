import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '../../utils';

// eslint-disable-next-line tailwindcss/no-contradicting-classname
const badgeVariants = cva(
  'inline-flex shrink-0 items-center rounded-full border border-slate-200 px-2.5 px-[10px] py-0.5 py-sm text-xs font-semibold transition-colors hover:shadow-[0px_1px_4px_0px_rgba(42,39,65,0.08)] focus:outline-none',
  {
    variants: {
      variant: {
        primary: 'border-transparent bg-primary text-slate-50',
        secondary:
          'hover:border-slate-hover border-transparent bg-slate-100 text-slate-900',
        destructive: 'border-transparent bg-red-500 text-slate-50 ',
        outline:
          'text-slate-950 hover:shadow-[0px_1px_4px_0px_rgba(42,39,65,0.08)]',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  },
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div {...props} className={cn(badgeVariants({ variant }), className)} />
  );
}

export { Badge, badgeVariants };
