import type { SVGProps } from 'react';
import * as React from 'react';
const SvgClockFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g id="Clock-fill">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.42839 1.3335C4.74649 1.3335 1.76172 4.31826 1.76172 8.00016C1.76172 11.6821 4.74649 14.6668 8.42839 14.6668C12.1103 14.6668 15.0951 11.6821 15.0951 8.00016C15.0951 4.31826 12.1103 1.3335 8.42839 1.3335ZM9.09505 4.66683C9.09505 4.29864 8.79658 4.00016 8.42839 4.00016C8.0602 4.00016 7.76172 4.29864 7.76172 4.66683V8.00016C7.76172 8.17697 7.83196 8.34654 7.95698 8.47157L9.95698 10.4716C10.2173 10.7319 10.6394 10.7319 10.8998 10.4716C11.1601 10.2112 11.1601 9.78911 10.8998 9.52876L9.09505 7.72402V4.66683Z"
        fill="#B8B9C8"
      />
    </g>
  </svg>
);
export default SvgClockFill;
