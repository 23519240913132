import { Fragment } from 'react';
import {
  DialogBackdrop,
  PopoverOverlayProps,
  TransitionChild,
} from '@headlessui/react';
import { cn } from '@zep/utils';

import S from './DialogOverlay.module.scss';

export const DialogOverlay = ({
  className,
  ...rest
}: PopoverOverlayProps<'div'>) => {
  return (
    <TransitionChild
      as={Fragment}
      enter={S.enter}
      enterFrom={S.enter_from}
      enterTo={S.enter_to}
      leave={S.leave}
      leaveFrom={S.leave_from}
      leaveTo={S.leave_to}>
      <DialogBackdrop {...rest} className={cn(S.overlay, className)} />
    </TransitionChild>
  );
};
