import type { SVGProps } from 'react';
import * as React from 'react';
const SvgBolt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      fillRule="evenodd"
      d="M13.31 2.05A1 1 0 0 1 14 3v6h5a1 1 0 0 1 .809 1.588l-8 11A1 1 0 0 1 10 21v-6H5a1 1 0 0 1-.809-1.588l8-11a1 1 0 0 1 1.118-.363M6.963 13H11a1 1 0 0 1 1 1v3.925L17.036 11H13a1 1 0 0 1-1-1V6.075z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBolt;
