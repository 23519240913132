import type { SVGProps } from 'react';
import * as React from 'react';
const SvgPersonFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.width || 24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}>
    <path
      fill={props.fill || '#5D5E69'}
      d="M12 2a6 6 0 1 0 0 12 6 6 0 0 0 0-12M3 21a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1 5 5 0 0 0-5-5H8a5 5 0 0 0-5 5"
    />
  </svg>
);
export default SvgPersonFill;
