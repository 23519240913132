import type { SVGProps } from 'react';
import * as React from 'react';
const SvgChange = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}>
    <path
      fill={props.fill ? props.fill : '#5D5E69'}
      d="M10.707 3.293a1 1 0 0 0-1.414 1.414L10.586 6H8c-.921 0-2.404.16-3.683 1.02C2.97 7.926 2 9.505 2 12s.969 4.074 2.317 4.98C5.597 17.84 7.08 18 8 18a1 1 0 1 0 0-2c-.745 0-1.763-.14-2.567-.68C4.698 14.826 4 13.905 4 12s.698-2.826 1.433-3.32C6.237 8.14 7.255 8 8 8h2.586L9.293 9.293a1 1 0 0 0 1.414 1.414l3-3a1 1 0 0 0 0-1.414zM13.293 20.707a1 1 0 0 0 1.414-1.414L13.414 18H16c.921 0 2.404-.16 3.683-1.02C21.03 16.074 22 14.495 22 12s-.969-4.074-2.317-4.98C18.403 6.16 16.92 6 16 6a1 1 0 1 0 0 2c.745 0 1.763.14 2.567.68C19.302 9.174 20 10.095 20 12s-.698 2.826-1.433 3.32c-.804.54-1.822.68-2.567.68h-2.586l1.293-1.293a1 1 0 0 0-1.414-1.414l-3 3a1 1 0 0 0 0 1.414z"
    />
  </svg>
);
export default SvgChange;
