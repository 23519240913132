import type { SVGProps } from 'react';
import * as React from 'react';
const SvgCurrencyWonCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      fillRule="evenodd"
      d="M6 13.134h1.488L8.418 17h2.167l.863-3.866h1.09L13.402 17h2.166l.937-3.866H18v-1.268h-1.19L17.749 8H15.7l-.77 3.866h-1.11L12.924 8h-1.848l-.897 3.866H9.076L8.3 8H6.252l.93 3.866H6zm3.628 1.094h-.08l-.22-1.094h.552zm2.405-3.369.226 1.007h-.531l.226-1.007zm2.419 3.381h-.08l-.259-1.106h.558z"
      clipRule="evenodd"
    />
    <path
      fill="#5D5E69"
      fillRule="evenodd"
      d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2M4 12a8 8 0 1 1 16 0 8 8 0 0 1-16 0"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCurrencyWonCircle;
