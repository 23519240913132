import type { SVGProps } from 'react';
import * as React from 'react';
const SvgPlayFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}>
    <path
      d="M4.42969 4.49403C4.42969 3.46498 5.54597 2.82382 6.43485 3.34233L12.4451 6.8483C13.3271 7.36281 13.3271 8.63721 12.4451 9.15171L6.43485 12.6577C5.54597 13.1762 4.42969 12.535 4.42969 11.506V4.49403Z"
      fill={props.fill ? props.fill : '#B8B9C8'}
    />
  </svg>
);
export default SvgPlayFill;
