import type { SVGProps } from 'react';
import * as React from 'react';
const SvgLinkDisconnect = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      d="M8.147 3.272a1 1 0 0 0-.632 1.265l.707 2.122a1 1 0 1 0 1.897-.633l-.707-2.121a1 1 0 0 0-1.265-.633M4.537 7.515a1 1 0 0 0-.632 1.897l2.121.707a1 1 0 1 0 .633-1.897zM19.461 16.483a1 1 0 1 0 .633-1.897l-2.122-.707a1 1 0 1 0-.632 1.897zM16.483 19.461a1 1 0 1 1-1.897.633l-.707-2.122a1 1 0 1 1 1.897-.632zM10.585 9.17a1 1 0 0 1 0-1.413l2.121-2.122a4 4 0 1 1 5.657 5.657l-2.121 2.121A1 1 0 0 1 14.828 12l2.12-2.121a2 2 0 0 0-2.828-2.829L12 9.171a1 1 0 0 1-1.415 0M13.413 16.242A1 1 0 1 0 12 14.828l-2.121 2.12a2 2 0 0 1-2.829-2.828L9.171 12a1 1 0 1 0-1.415-1.415l-2.12 2.121a4 4 0 0 0 5.656 5.657z"
    />
  </svg>
);
export default SvgLinkDisconnect;
