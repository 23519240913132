import type { SVGProps } from 'react';
import * as React from 'react';
const SvgList = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      d="M6.2 6a1.2 1.2 0 1 1-2.4 0 1.2 1.2 0 0 1 2.4 0M7 6c0-.552.364-1 .813-1h11.375c.448 0 .812.448.812 1s-.364 1-.812 1H7.813C7.363 7 7 6.552 7 6M7.813 11c-.45 0-.813.448-.813 1s.364 1 .813 1h11.375c.448 0 .812-.448.812-1s-.364-1-.812-1zM7 18c0-.552.364-1 .813-1h11.375c.448 0 .812.448.812 1s-.364 1-.812 1H7.813C7.363 19 7 18.552 7 18M6.2 12a1.2 1.2 0 1 1-2.4 0 1.2 1.2 0 0 1 2.4 0M5 19.2a1.2 1.2 0 1 0 0-2.4 1.2 1.2 0 0 0 0 2.4"
    />
  </svg>
);
export default SvgList;
