import type { SVGProps } from 'react';
import * as React from 'react';
const SvgBookFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      d="M11 4.513A11.8 11.8 0 0 0 7.5 4c-1.526 0-2.755.271-3.612.548a9 9 0 0 0-1.001.389 6 6 0 0 0-.357.18l-.025.014-.009.005-.003.002h-.001M11 4.513v15.12a8 8 0 0 0-.503-.181A9.8 9.8 0 0 0 7.5 19a9.8 9.8 0 0 0-2.997.451 7 7 0 0 0-.775.3 4 4 0 0 0-.219.11A1 1 0 0 1 2 19V6a1 1 0 0 1 .489-.86l-.108.064.11-.064M13 19.634q.21-.087.503-.183A9.8 9.8 0 0 1 16.5 19a9.8 9.8 0 0 1 2.997.451 7 7 0 0 1 .775.3 4 4 0 0 1 .219.11l.003.002-.002-.002A1 1 0 0 0 22 19V6a1 1 0 0 0-.49-.86l-.002-.001h-.001l-.003-.003-.01-.005-.024-.014-.08-.044a6 6 0 0 0-.277-.136 9 9 0 0 0-1-.389A11.8 11.8 0 0 0 16.5 4c-1.46 0-2.649.249-3.5.513z"
    />
  </svg>
);
export default SvgBookFill;
