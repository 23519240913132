import type { SVGProps } from 'react';
import * as React from 'react';
const SvgArrowTurnUpright = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      d="M18.424 8.883a1 1 0 0 0-.217-1.09l-3-3a1 1 0 1 0-1.414 1.414L15.086 7.5H10a5 5 0 0 0-5 5V18a1 1 0 1 0 2 0v-5.5a3 3 0 0 1 3-3h5.086l-1.293 1.293a1 1 0 0 0 1.414 1.414l3-3M18.424 8.883a1 1 0 0 1-.215.322z"
    />
  </svg>
);
export default SvgArrowTurnUpright;
