import type { SVGProps } from 'react';
import * as React from 'react';
const SvgPaperFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      fillRule="evenodd"
      d="M12.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9.5h-6.495A1.01 1.01 0 0 1 12.5 8.483zM8 13a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1m0 4a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1"
      clipRule="evenodd"
    />
    <path
      fill="#5D5E69"
      d="M15.5 2.22a.75.75 0 0 0-.53-.22H14v5.25c0 .414.336.75.75.75H20V7a.75.75 0 0 0-.22-.53z"
    />
  </svg>
);
export default SvgPaperFill;
