import { forwardRef } from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cn } from '@zep/utils';
import { cva, VariantProps } from 'class-variance-authority';

const DefaultVariant = cn([
  'disabled:pointer-events-none disabled:opacity-[.54]',
  'rounded-md ring-offset-background focus-visible:ring-ring inline-flex',
  'items-center justify-center whitespace-nowrap text-sm font-semibold transition-colors',
  'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2',
]);

const buttonVariants = cva(DefaultVariant, {
  variants: {
    variant: {
      default: 'bg-primary text-primary-foreground hover:bg-primary-hover',
      destructive:
        'bg-destructive text-destructive-foreground hover:bg-destructive/90',
      outline:
        'border-input bg-background border text-gray-600 hover:bg-accent hover:text-accent-foreground',
      secondary:
        'border border-solid border-[rgba(0,0,0,0.03)]  bg-primaryBg text-primary hover:bg-primaryBg-hover',
      sub: 'border border-solid  border-[rgba(0,0,0,0.03)] bg-gray-100 text-gray-600 hover:bg-gray-200',
      link: 'text-primary underline-offset-4 hover:underline',
    },
    size: {
      default: 'h-10 rounded-[8px] px-4 py-2',
      sm: 'h-9 rounded-[8px] px-3',
      lg: 'h-[48px] rounded-[8px] p-md',
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'default',
  },
});

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  rounded?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, rounded, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size, className }),
          rounded && 'rounded-[80px]',
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
