import type { SVGProps } from 'react';
import * as React from 'react';
const SvgPersonPlusFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      d="M4 8a6 6 0 1 1 12 0A6 6 0 0 1 4 8M2 22a1 1 0 0 1-1-1 5 5 0 0 1 5-5h8a5 5 0 0 1 5 5 1 1 0 0 1-1 1zM20 12a1 1 0 1 0-2 0v1h-1a1 1 0 1 0 0 2h1v1a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1z"
    />
  </svg>
);
export default SvgPersonPlusFill;
