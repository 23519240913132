import React, { ComponentPropsWithRef } from 'react';
import { NoSSR } from '@zep/components';
import { cn } from '@zep/utils';

import styles from './Header.module.scss';

interface Props {
  children?: React.ReactNode;
  props?: ComponentPropsWithRef<'header'>;
  className?: string;
}

export const HeaderContainer = ({ children, className, props }: Props) => {
  return (
    <NoSSR>
      <header
        className={cn(
          styles.header_container,
          'fixed top-0 min-h-[60px] w-full bg-[white]',
          className,
        )}
        {...props}>
        {children}
      </header>
    </NoSSR>
  );
};
