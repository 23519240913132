'use client';

import * as React from 'react';
import { ReactNode } from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import { SelectScrollUpButton } from '@radix-ui/react-select';
import { Checkbox } from '@zep/ui';
import { cn } from '@zep/utils';
import { ChevronDown } from 'lucide-react';

//https://github.com/radix-ui/primitives/issues/1658 이 이슈를 위한 방어로직
const Overlay = ({ open }: { open: boolean }) => {
  const [visible, setVisible] = React.useState<boolean>(open);
  React.useEffect(() => {
    if (!open) {
      const timer = setTimeout(() => {
        setVisible(false);
      }, 200);
      return () => {
        clearTimeout(timer);
      };
    }
    setVisible(true);
    return () => {};
  }, [open]);

  return visible ? (
    <div
      onClick={e => e.stopPropagation()}
      className={
        'fixed inset-0 isolate z-[1050] bg-[rgb(0_0_0_/_0%)] opacity-80'
      }></div>
  ) : null;
};

type MultipleSelectProps = React.ComponentPropsWithoutRef<
  typeof SelectPrimitive.Root
> & {
  values: string[];
  onValuesChange: (values: string[]) => void;
};

const Select: React.FC<Omit<MultipleSelectProps, 'onValueChange'>> = ({
  values = [],
  children,
  onValuesChange,
  ...props
}) => {
  const [value, setValue] = React.useState('');
  const handleValueChange = (curValue: string) => {
    setValue(curValue.concat(',')); // 이전과 같은 것을 클릭해도 onValueChange 호출하기 위함

    const newSelectedValues = values?.includes(curValue)
      ? values.filter(v => v !== curValue)
      : [...values, curValue];
    onValuesChange(newSelectedValues);
  };

  return (
    <SelectPrimitive.Root
      value={value}
      onValueChange={handleValueChange}
      {...props}>
      {children}
    </SelectPrimitive.Root>
  );
};
Select.displayName = 'MultipleSelect';

const SelectValues = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Value>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Value> & {
    text: ReactNode;
  }
>(({ ...props }, ref) => {
  return (
    <SelectPrimitive.Value {...props} ref={ref}>
      {props.text}
    </SelectPrimitive.Value>
  );
});
SelectValues.displayName = SelectPrimitive.Value.displayName;

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> & {
    open?: boolean;
  }
>(({ className, children, open, ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    className={cn(
      'focus:outline-none flex h-[47px] w-[240px] items-center justify-between gap-[2px] self-stretch rounded-[8px] border border-solid border-[#E9EAF2] p-[12px]',
      'data-[state="open"]:border-[#A49BFF]',
      'data-[placeholder]:text-text-disabled',
      className,
    )}
    {...props}>
    {children}
    <SelectPrimitive.Icon>
      {open ? (
        <img src="/icons/icon_caret-up.svg" alt="check" />
      ) : (
        <img src="/icons/icon_caret-down.svg" alt="check" />
      )}
    </SelectPrimitive.Icon>
  </SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectScrollDownButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollDownButton
    ref={ref}
    className={cn(
      'flex cursor-default items-center justify-center py-1',
      className,
    )}
    {...props}>
    <ChevronDown className="size-4" />
  </SelectPrimitive.ScrollDownButton>
));
SelectScrollDownButton.displayName =
  SelectPrimitive.ScrollDownButton.displayName;

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content> & {
    open: boolean;
    handleClose: () => void;
  }
>(
  (
    { className, children, handleClose, open, position = 'popper', ...props },
    ref,
  ) => (
    <SelectPrimitive.Portal>
      <>
        <Overlay open={open} />
        <SelectPrimitive.Content
          ref={ref}
          side="bottom"
          sideOffset={0}
          position={position}
          onPointerDownOutside={e => {
            handleClose();
            e.preventDefault();
          }}
          className={cn(
            'rounded-[8px] max-h-[50vh] w-[240px] border border-gray-200 shadow-[0_1px_4px_0_rgb(42_39_65_/_8%)] px-[4px] bg-white z-[1051]',
            className,
          )}
          {...props}>
          <SelectScrollUpButton />
          <SelectPrimitive.Viewport className={'flex h-auto w-full flex-col'}>
            {children}
          </SelectPrimitive.Viewport>
          <SelectScrollDownButton />
        </SelectPrimitive.Content>
      </>
    </SelectPrimitive.Portal>
  ),
);
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item> & {
    values: string[];
  }
>(({ className, children, values, ...props }, ref) => {
  const isSelected = values?.includes(props.value);

  return (
    <SelectPrimitive.Item
      ref={ref}
      className={cn(
        'focus:outline-none flex min-h-[36px] w-full cursor-pointer items-center gap-[4px] rounded-[4px] px-[10px] py-[6px] text-body-sm',
        'focus:bg-gray-50',
        'data-[disabled]:pointer-events-none data-[disabled]:opacity-50 font-medium',
        isSelected && 'text-text-strong bg-[#F3F2FF]',
        className,
      )}
      {...props}>
      <Checkbox checked={isSelected} />
      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
  );
});
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    className={cn('-mx-1 my-1 h-px bg-muted', className)}
    {...props}
  />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export {
  Select,
  SelectValues,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectSeparator,
  SelectScrollUpButton,
  SelectScrollDownButton,
};
