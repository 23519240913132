import type { SVGProps } from 'react';
import * as React from 'react';
const SvgGlobe = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      fillRule="evenodd"
      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10M7.404 10.313c-.458 2.902.04 6.18 2.388 9.379a8.003 8.003 0 0 1 .08-15.406C8.78 5.864 7.777 7.948 7.403 10.313m6.725 9.4a8.004 8.004 0 0 0 .079-15.404c2.347 3.197 2.846 6.476 2.388 9.378-.374 2.365-1.376 4.45-2.467 6.027m-2.142-.466c-2.533-3.004-3.018-6.021-2.607-8.622.376-2.383 1.515-4.463 2.633-5.872 2.533 3.004 3.018 6.021 2.607 8.622-.376 2.383-1.515 4.463-2.633 5.872"
      clipRule="evenodd"
    />
    <path
      fill="#5D5E69"
      fillRule="evenodd"
      d="M20 10H4V8h16zM20 16H4v-2h16z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgGlobe;
