import type { SVGProps } from 'react';
import * as React from 'react';

const CheckSmallCircleFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 9.5C2 5.35786 5.35786 2 9.5 2C13.6421 2 17 5.35786 17 9.5C17 13.6421 13.6421 17 9.5 17C5.35786 17 2 13.6421 2 9.5ZM13.0606 7.74828C13.3358 7.43869 13.3079 6.96464 12.9983 6.68945C12.6887 6.41426 12.2146 6.44215 11.9395 6.75173L8.50001 10.6211L7.06056 9.00173C6.78538 8.69215 6.31132 8.66426 6.00173 8.93945C5.69215 9.21464 5.66426 9.68869 5.93945 9.99828L7.93945 12.2483C8.08178 12.4084 8.28578 12.5 8.50001 12.5C8.71424 12.5 8.91824 12.4084 9.06057 12.2483L13.0606 7.74828Z"
      fill={props.fill ? props.fill : '#6758FF'}
    />
  </svg>
);
export default CheckSmallCircleFill;
