import type { SVGProps } from 'react';
import * as React from 'react';
const SvgChevronDoubleLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      d="M11.707 5.293a1 1 0 0 0-1.414 0l-6 6a1 1 0 0 0 0 1.414l6 6a1 1 0 0 0 1.414-1.414L6.414 12l5.293-5.293a1 1 0 0 0 0-1.414m6 0a1 1 0 0 0-1.414 0l-6 6a1 1 0 0 0 0 1.414l6 6a1 1 0 0 0 1.414-1.414L12.414 12l5.293-5.293a1 1 0 0 0 0-1.414"
    />
  </svg>
);
export default SvgChevronDoubleLeft;
