import { forwardRef } from 'react';

import { cn } from '../../utils';

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ isBig, className, ...rest }, ref) => (
    <input
      className={cn(
        'px-md py-[14px] rounded-[6px] border-[1px] border-solid border-gray-200 text-gray-700 placeholder-gray-400',
        'focus:border-primary',
        isBig ? 'text-[24px] font-extrabold' : 'text-[18px] font-bold',
        className,
      )}
      {...rest}
      ref={ref}
    />
  ),
);
TextField.displayName = 'TextField';

type TextFieldProps = {
  isBig?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;
