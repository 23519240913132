import type { SVGProps } from 'react';
import * as React from 'react';
const SvgFaceFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      fillRule="evenodd"
      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10M10 8a1 1 0 0 0-1 1v1a1 1 0 1 0 2 0V9a1 1 0 0 0-1-1m3 1a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0zm-5 4a4 4 0 0 0 8 0z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFaceFill;
