import type { SVGProps } from 'react';
import * as React from 'react';
const SvgAddSmallSlashRectangle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      d="M2.707 2.293a1 1 0 0 0 0 1.414l16.97 16.97a1 1 0 0 0 1.415-1.413L4.122 2.293a1 1 0 0 0-1.415 0M3 19V6.05l2 2V19h10.95l2 2H5a2 2 0 0 1-2-2M6.995 3l2 2H19v10.005l2 2V5a2 2 0 0 0-2-2z"
    />
    <path
      fill="#5D5E69"
      d="M7 12a1 1 0 0 1 .952-.999L9.95 13H8a1 1 0 0 1-1-1M11 16v-1.95l1.999 1.998a1 1 0 0 1-2-.048M17 12a1 1 0 0 1-.296.71L14.994 11H16a1 1 0 0 1 1 1M11.29 7.295 13 9.005V8a1 1 0 0 0-1.71-.705"
    />
  </svg>
);
export default SvgAddSmallSlashRectangle;
