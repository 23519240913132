import type { SVGProps } from 'react';
import * as React from 'react';
const SvgExclamationmarkSmallTriangleFill = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      fillRule="evenodd"
      d="M13.77 2.215c-.75-1.425-2.79-1.425-3.54 0L1.888 18.068c-.7 1.332.265 2.931 1.77 2.931h16.687c1.505 0 2.471-1.6 1.77-2.931zM11 11.999a1 1 0 1 0 2 0V9a1 1 0 1 0-2 0zm1 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgExclamationmarkSmallTriangleFill;
