import type { SVGProps } from 'react';
import * as React from 'react';
const SvgRotateCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      d="M5.939 8.446C7.153 6.32 9.417 4.9 12 4.9c3.815 0 6.79 3.123 6.8 7.08l-.99-.99a1 1 0 0 0-1.414 1.414l2.142 2.142-.009.029.057.019.507.507a1 1 0 0 0 1.3.098l.031.01.018-.048.065-.06 2.697-2.697a1 1 0 0 0-1.414-1.414l-.99.99C20.79 7.009 17.008 2.9 12 2.9c-3.338 0-6.246 1.838-7.798 4.554a1 1 0 1 0 1.737.992M3.283 13.27l-.972.973a1 1 0 0 1-1.415-1.414l2.697-2.697a1 1 0 0 1 1.414 0l2.697 2.697a1 1 0 0 1-1.414 1.414l-.988-.988C5.854 16.608 8.592 19.1 12 19.1c2.086 0 3.96-.924 5.246-2.399a1 1 0 0 1 1.508 1.314A8.94 8.94 0 0 1 12 21.1c-4.581 0-8.136-3.438-8.717-7.83"
    />
  </svg>
);
export default SvgRotateCircle;
