import type { SVGProps } from 'react';
import * as React from 'react';
const SvgWhiteboard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      d="M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zm14 8.586L11.586 19H5V5h14zM14.414 19 19 14.414V19z"
    />
  </svg>
);
export default SvgWhiteboard;
