import type { SVGProps } from 'react';
import * as React from 'react';
const SvgInformationmarkSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      d="M12 6a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M13 18h-2v-8h2z"
    />
  </svg>
);
export default SvgInformationmarkSmall;
