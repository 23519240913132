import type { SVGProps } from 'react';
import * as React from 'react';
const SvgHammerFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      d="M19.665 4.594a2 2 0 0 0-1.664-1.607l-3.165-.482a2 2 0 0 0-1.302.245l-6.062 3.5a2 2 0 0 0-.776 2.652l-.412.238a2 2 0 0 0-2.685-.655l-.866.5A2 2 0 0 0 2 11.717l1 1.732a2 2 0 0 0 2.732.732l.866-.5a2 2 0 0 0 .732-2.732l-.045-.078.408-.236.046.08a2 2 0 0 0 2.732.732l.013-.008v-.001l5.844-3.375.002.001L18.7 6.697a2 2 0 0 0 .965-2.103M16.558 8.931l-5.196 3 5.067 8.776a2 2 0 0 0 2.732.732l1.732-1a2 2 0 0 0 .732-2.732z"
    />
  </svg>
);
export default SvgHammerFill;
