import type { SVGProps } from 'react';
import * as React from 'react';
const SvgCheckSmallCircleColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#E9EAF2"
      fillRule="evenodd"
      d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2"
      clipRule="evenodd"
    />
    <path
      fill="#7A7B89"
      d="M16.664 8.253a1 1 0 0 1 .083 1.411l-5.333 6a1 1 0 0 1-1.495 0l-2.666-3a1 1 0 0 1 1.494-1.328l1.92 2.159 4.586-5.16a1 1 0 0 1 1.411-.082"
    />
  </svg>
);
export default SvgCheckSmallCircleColor;
