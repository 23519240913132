import type { SVGProps } from 'react';
import * as React from 'react';
const SvgBoltFill = (
  props: SVGProps<SVGSVGElement> & {
    defsId?: string;
  },
) => {
  const { fill, ...restProps } = props;
  return (
    <svg
      style={{
        width: `${props.width}px`,
        height: `${props.height}px`,
        minWidth: `${props.width}px`,
        minHeight: `${props.height}px`,
      }}
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...restProps}>
      <path
        fill={fill ? fill : '#5D5E69'}
        d="M13.583 3a.583.583 0 0 0-1.055-.344l-8 11a.583.583 0 0 0 .471.927h5.417v6.416a.583.583 0 0 0 1.055.344l8-11A.583.583 0 0 0 19 9.416h-5.416z"
      />

      <defs>
        <linearGradient
          id="paint0_linear_6156_7919"
          x1="2.94434"
          y1="7.99927"
          x2="13.0555"
          y2="7.99927"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#8478FF" />
          <stop offset="1" stopColor="#3DA9FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgBoltFill;
