import type { SVGProps } from 'react';
import * as React from 'react';
const SvgRectangleDash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}>
    <path
      fill="#5D5E69"
      fillRule="evenodd"
      d="M6 5a1 1 0 0 0-1 1v1.5a1 1 0 0 1-2 0V6a3 3 0 0 1 3-3h1.5a1 1 0 0 1 0 2zm3.5-1a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1m6 0a1 1 0 0 1 1-1H18a3 3 0 0 1 3 3v1.5a1 1 0 1 1-2 0V6a1 1 0 0 0-1-1h-1.5a1 1 0 0 1-1-1M4 9.5a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-3a1 1 0 0 1 1-1m16 0a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-3a1 1 0 0 1 1-1m-16 6a1 1 0 0 1 1 1V18a1 1 0 0 0 1 1h1.5a1 1 0 1 1 0 2H6a3 3 0 0 1-3-3v-1.5a1 1 0 0 1 1-1m16 0a1 1 0 0 1 1 1V18a3 3 0 0 1-3 3h-1.5a1 1 0 1 1 0-2H18a1 1 0 0 0 1-1v-1.5a1 1 0 0 1 1-1M9.5 20a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgRectangleDash;
